import * as React from "react";

import Routers from "./components/Router";

const App = () => {
  return (
    <div>
      <Routers />
    </div>
  );
};

export default App;
